import React, { Component } from "react";
import Footer from '../components/footer';
import { ethers } from 'ethers'
import { create as ipfsHttpClient } from 'ipfs-http-client'
import Web3Modal from 'web3modal'



import {
    nftaddress, nftmarketaddress
} from '../../config'

import NFT from '../../contracts/NFT.sol/NFT.json'
import Market from '../../contracts/Market.sol/NFTMarket.json'


const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0')


export default class Createpage extends Component {

    constructor(props) {
        super(props);
    this.state = {
        url:'' ,nameNft:'' , price: '', description: '', royalties:'',nftOk:''
    };
         this.handleInputChange = this.handleInputChange.bind(this);
         this.uploadFile = this.uploadFile.bind(this);
         this.createMarket = this.createMarket.bind(this);
         this.createSale = this.createSale.bind(this);
  }

    async uploadFile(e) {
        const file = e.target.files[0]
        try {
            const added = await client.add(file, {
                progress: (prog) => console.log(`received: ${prog}`)
            })
            const url = `https://ipfs.infura.io/ipfs/${added.path}`
            this.setState({
                url:url
            });
        } catch (error) {
            console.log('Error uploading file: ', error)
        }


    }

    async handleInputChange(e) {
        const target = e.target;

        this.setState({
            [target.name]: target.value,
        });
       // console.log(this.state)


    }

    async createMarket() {
        const {description, price, url} = this.state
        const name = this.state.nameNft
        // console.log(this.state)
        if (!name || !description || !price || !url) return
        /* first, upload to IPFS */
        const data = JSON.stringify({
            name, description, image: url
        })
        try {
            const added = await client.add(data)
            const marketUrl = `https://ipfs.infura.io/ipfs/${added.path}`
            /* after file is uploaded to IPFS, pass the URL to save it on Polygon */
           //
            //console.log(marketUrl)
            await this.createSale(marketUrl)
            // createSale(marketUrl)
        } catch (error) {
            console.log('Error uploading file: ', error)
        }
    }

    async createSale(url) {
        const web3Modal = new Web3Modal()
        const connection = await web3Modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()
        /* next, create the item */
        let contract = new ethers.Contract(nftaddress, NFT.abi, signer)
        console.log(contract)
        let transaction = await contract.createToken(url)
        let tx = await transaction.wait()
        let event = tx.events[0]
        let value = event.args[2]
        let tokenId = value.toNumber()

        const price = ethers.utils.parseUnits(this.state.price, 'ether')

        /* then list the item for sale on the marketplace */
        contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
        let listingPrice = await contract.getListingPrice()
        listingPrice = listingPrice.toString()

        transaction = await contract.createMarketItem(nftaddress, tokenId, price, { value: listingPrice })
        await transaction.wait()
        this.setState({
            nftOk:1
        });
    }



render() {

    return (

      <div>

          <section className='jumbotron breadcumb no-bg'>
              <div className='mainbreadcumb'>
                  <div className='container'>
                      <div className='row m-10-hor'>
                          <div className='col-12'>
                              <h1 className='text-center'>Create NFT</h1>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

        <section className='container'>
            {this.state.nftOk ?

                <h5>Listed</h5> :

                <div className="row">
                    <div className="col-lg-7 offset-lg-1 mb-5">
                        <form id="form-create-item" className="form-border" action="#">
                            <div className="field-set">
                                <h5>Upload file</h5>

                                <div className="d-create-file">
                                    <p id="file_name">PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p>
                                    <p key="{index}">PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p>
                                    <div className='browse'>
                                        <input type="button" id="get_file" className="btn-main" value="Browse"/>
                                        <input id='upload_file' name="files" type="file" multiple onChange={this.uploadFile} />
                                    </div>

                                </div>

                                <div className="spacer-single"></div>

                                <h5>Title</h5>
                                <input type="text" name="nameNft" id="item_title" className="form-control" placeholder="e.g. 'Crypto Funk"
                                       value={this.state.nameNft}
                                       onChange={this.handleInputChange}/>

                                <div className="spacer-10"></div>

                                <h5>Description</h5>
                                <textarea data-autoresize name="description" id="item_desc" className="form-control" placeholder="e.g. 'This is very limited item'"
                                          value={this.state.description}
                                          onChange={this.handleInputChange}></textarea>

                                <div className="spacer-10"></div>

                                <h5>Price</h5>
                                <input type="text" name="price" id="item_price" className="form-control" placeholder="enter price for one item (ETH)"
                                       value={this.state.price}
                                       onChange={this.handleInputChange}/>

                                <div className="spacer-10"></div>

                                <h5>Royalties</h5>
                                <input type="text" name="royalties" id="item_royalties" className="form-control" placeholder="suggested: 0, 10%, 20%, 30%. Maximum is 70%" value={this.state.royalties}
                                       onChange={this.handleInputChange} />

                                <div className="spacer-10"></div>

                                <input type="button" id="submit" className="btn-main" value="Create Item" onClick={this.createMarket}/>
                            </div>
                        </form>
                    </div>
                    {
                        this.state.url  && (
                            <div className="col-lg-3 col-sm-6 col-xs-12">
                                <h5>Preview item</h5>
                                <div className="nft__item m-0">

                                    <div className="nft__item_wrap">
                          <span>

                                     <img src={this.state.url} id="get_file_2" className="lazy nft__item_preview" />


                          </span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>

            }


      </section>

        <Footer />
      </div>
   );
  }


}