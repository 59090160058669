import React, { Component } from "react";
import styled from "styled-components";
import Clock from "./Clock";


const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

export default class Responsive extends Component {
   dummyData = [{
        deadline:"December, 30, 2021",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/author/author-1.jpg",
        previewImg: "./img/items/static-1.jpg",
        title: "Pinky Oceazzzzn",
        price: "0.08 ETH",
        bid: "1/20",
        likes: 50
    },
    {
        deadline:"",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/author/author-10.jpg",
        previewImg: "./img/items/static-2.jpg",
        title: "Deep Sea Phantasy",
        price: "0.06 ETH",
        bid: "1/22",
        likes: 80
    }]

  constructor(props) {
    super(props);
    this.state = {
        nfts: this.dummyData,
    height: 0
    };
    this.onImgLoad = this.onImgLoad.bind(this);


    }


    onImgLoad({target:img}) {
        let currentHeight = this.state.height;
        if(currentHeight < img.offsetHeight) {
            this.setState({
                height: img.offsetHeight
            })
        }
    }

 render() {
  return (
      <div className='row'>
          {this.props.nfts.map( (nft, index) => (
              <div key={index} className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="nft__item">


                      <div className="nft__item_wrap" style={{height: `${this.state.height}px`}}>
                          <Outer>
                        <span>
                            <img onLoad={this.onImgLoad} src={nft.image} className="lazy nft__item_preview" alt=""/>
                        </span>
                          </Outer>
                      </div>
                      <div className="nft__item_info">
                        <span onClick={()=> window.open(nft.nftLink, "_self")}>
                            <h4>{nft.title}</h4>
                        </span>
                          <div className="nft__item_price">
                              {nft.price}<span>{nft.bid}</span>
                          </div>

                      </div>
                  </div>
              </div>
          ))}

      </div>
  );
}
}