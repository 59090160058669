import React from "react";
import ColumnSold from '../components/ColumnSold';
import ColumnOwned from '../components/ColumnOwned';
import ColumnOnSale from '../components/ColumnOnSale';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Web3Modal from "web3modal"


import {
    nftmarketaddress, nftaddress
} from '../../config'






import NFT from '../../artifacts/contracts/NFT.sol/NFT.json'
import Market from '../../artifacts/contracts/Market.sol/NFuTureNftMarket.json'


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;


const Colection= function() {

    const [nfts, setNfts] = useState([])
    const [nftsOnsale, setNftsOnsale] = useState([])
    const [nftsSold, setNftsSold] = useState([])
    const [ownedAddress, setownedAddress] = useState([])
    const [loadingState, setLoadingState] = useState('not-loaded')
    useEffect(() => {
        loadNFTs()
    }, [])

    async function loadNFTs() {
        const web3Modal = new Web3Modal({
            network: "mainnet",
            cacheProvider: true,
        })
        const connection = await web3Modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()
        setownedAddress(await provider.send("eth_requestAccounts", []));

        const marketContract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
        const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
        const data = await marketContract.fetchMyNFTs()
        const data1 = await marketContract.fetchItemsCreated()

        const items = await Promise.all(data.map(async i => {
            const tokenUri = await tokenContract.tokenURI(i.tokenId)
            const meta = await axios.get(tokenUri)
            let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
            let item = {
                price,
                tokenId: i.tokenId.toNumber(),
                seller: i.seller,
                owner: i.owner,
                image: meta.data.image,
                title:meta.data.name,
                url:meta.data.url

            }
            return item
        }))
        const items2 = await Promise.all(data1.map(async i => {
            const tokenUri = await tokenContract.tokenURI(i.tokenId)
            const meta = await axios.get(tokenUri)
            let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
            let item = {
                price,
                tokenId: i.tokenId.toNumber(),
                seller: i.seller,
                owner: i.owner,
                sold: i.sold,
                image: meta.data.image,
                title:meta.data.name,
                url:meta.data.url
            }
            return item
        }))
        const soldItems = items2.filter(i => i.sold)

        setNfts(items)
        setNftsOnsale(items2)
        setNftsSold(soldItems)

        setLoadingState('loaded')
          console.log(items2)
          console.log(items)
    }

    const [openMenu, setOpenMenu] = React.useState(true);
    const [openMenu1, setOpenMenu1] = React.useState(false);
    const [openMenu2, setOpenMenu2] = React.useState(false);

    const handleBtnClick = (): void => {
      setOpenMenu(!openMenu);
      setOpenMenu1(false);
      setOpenMenu2(false);
      document.getElementById("Mainbtn").classList.add("active");
      document.getElementById("Mainbtn1").classList.remove("active");
      document.getElementById("Mainbtn2").classList.remove("active");
    };
    const handleBtnClick1 = (): void => {
      setOpenMenu1(!openMenu1);
      setOpenMenu(false);
      setOpenMenu2(false);
      document.getElementById("Mainbtn1").classList.add("active");
      document.getElementById("Mainbtn").classList.remove("active");
      document.getElementById("Mainbtn2").classList.remove("active");
    };
    const handleBtnClick2 = (): void => {
        setOpenMenu2(!openMenu2);
        setOpenMenu(false);
        setOpenMenu1(false);
        document.getElementById("Mainbtn2").classList.add("active");
        document.getElementById("Mainbtn").classList.remove("active");
        document.getElementById("Mainbtn1").classList.remove("active");
    };



return (
<div>
<GlobalStyles/>

  <section id='profile_banner' className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/4.jpg'})`}}>
    <div className='mainbreadcumb'>
    </div>
  </section>

  <section className='container d_coll no-top no-bottom'>
    <div className='row'>
      <div className="col-md-12">
         <div className="d_profile">
                  <div className="profile_avatar">
                      <div className="d_profile_img">
                          <img src="./img/author/author-1.jpg" alt=""/>
                          <i className="fa fa-check"></i>
                      </div>

                      <div className="profile_name">
                          <h4>
                              Abstraction
                              <div className="clearfix"></div>
                              <span id="wallet" className="profile_wallet">{ownedAddress[0]}</span>
                              <button id="btn_copy" title="Copy Text">Copy</button>
                          </h4>
                      </div>
                  </div>

          </div>
      </div>
    </div>
  </section>

  <section className='container no-top'>
        <div className='row'>
          <div className='col-lg-12'>
              <div className="items_filter">
                <ul className="de_nav">
                    <li id='Mainbtn' className="active"><span onClick={handleBtnClick}>On Sale</span></li>
                    <li id='Mainbtn1' className=""><span onClick={handleBtnClick1}>Owned</span></li>
                    <li id='Mainbtn2' className=""><span onClick={handleBtnClick2}>Sold</span></li>
                </ul>
            </div>
          </div>
        </div>
      {openMenu && (
        <div id='zero1' className='onStep fadeIn'>
         <ColumnOnSale nfts={nftsOnsale}/>
        </div>
      )}
      {openMenu1 && (
        <div id='zero2' className='onStep fadeIn'>
            <ColumnOwned nfts={nfts}/>
        </div>
      )}
      {openMenu2 && (
          <div id='zero3' className='onStep fadeIn'>
              <ColumnSold nfts={nftsSold}/>
          </div>
      )}
      </section>


  <Footer />
</div>
);
}
export default Colection;